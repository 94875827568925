<template>
  <MultiSelectCombo ref="multiSelectCombo" :buttonText="this.$t('locationFilter.location')" :uniqueComboName="this.uniqueComboName"
    :comboOptions="this.locationsArray" leftButtonIconName="locationIcon" signalOnChange="update:filterByLocation"
    :showAllNoneOptions=true :capitalize="false"/>
</template>

<script>
import getAliasOrNameFromObject from '@/utils/objects';
import useTransitStore from '@/stores/TransitStore';
import MultiSelectCombo from './generic/MultiSelectCombo.vue';

export default {
  name: 'LocationFilter',
  components: {
    MultiSelectCombo,
  },
  data() {
    return {
      locationsArray: [],
    };
  },
  setup() {
    return {
      getAliasOrNameFromObject,
    };
  },
  props: {
    uniqueComboName: {
      type: String,
      required: true,
    },
  },
  methods: {
    computeLocationsArray(locations) {
      this.locationsArray = [];
      Object.values(locations).forEach((location) => {
        this.locationsArray.push({ id: location.id, value: getAliasOrNameFromObject(location) });
      });
    },
    selectAllOptions() {
      this.$refs.multiSelectCombo.selectAll();
    },
  },
  mounted() {
    this.emitter.on('locations-updated', (locations) => {
      this.computeLocationsArray(locations);
    });
    this.computeLocationsArray(useTransitStore().locations);
  },
  unmounted() {
    this.emitter.off('locations-updated');
  },
};
</script>
