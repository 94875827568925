<template>
  <div class="flex flex-col bg-[#1e3c53] rounded-xl px-3 mb-5">
    <div class="flex grid grid-cols-3 py-3">
      <div class="flex col-start-2 place-content-center gap-2 items-center">
        <SearchBar
          uniqueSearchBarName='objectTabSearchBar'
          :currentFetchParams='sessionFilters'
          @update-fetch-params="onFilterUpdated"/>
        <button @click="showFilter = !showFilter"
          class="bg-sky-500 text-white rounded-full hover:bg-sky-700
          transition-colors duration-300 w-9 h-9 p-1 hover:scale-105"
          :title="$t('objectsTab.showObjectsFilter')">
          <img :src="filterIcon" alt="filter icon">
        </button>
      </div>
      <PaginationControl class="col-start-3 place-content-end"
        v-model:elementsPerPage='elementsPerPage'
        v-model="currentPage"
        v-model:totalItems='totalItems'
        @update:elementsPerPage="handleElementsPerPageChange"
      />
    </div>
    <hr class="border-t border-sky-300 mb-2"/>
    <LoadingOverlay v-if="isLoading" :show="isLoading" />
    <div v-else-if="transitStore.errors.fetchMetaObjects" class="p-4 mt-4 border rounded
      border-red-500 text-red-500">
      {{ transitStore.errors.fetchMetaObjects }}
    </div>
    <div v-else-if="this.transitStore.metaObject.meta_objects?.length === 0"
    class="p-4 m-4 border rounded bg-sky-100 border-sky-500 text-sky-800">
    <p class="text-2xl font-bold">{{ $t('objectsTab.noObjectsToShow') }}</p>
    </div>
    <div v-else class="flex flex-wrap justify-start bg-[#1e3c53] gap-4">
        <ObjectCard
          v-for="(metaObject, index) in this.transitStore.metaObject.meta_objects"
          :key="`${metaObject.id}-${index}`"
          :metaObject="metaObject"
        />
    </div>
    <div class="flex justify-center mt-2 pb-5">
      <PaginationControl
        v-show="!this.isLoading"
        class="col-start-4 place-content-end"
        v-model:elementsPerPage='elementsPerPage'
        v-model="currentPage"
        v-model:totalItems='totalItems'
        @update:elementsPerPage="handleElementsPerPageChange"/>
    </div>
  </div>
  <ObjectFilterPopUp
    :show="showFilter"
    @close="showFilter = false"
    @filter-updated="onFilterUpdated"
    :currentFetchParams='sessionFilters'
    :metaObjectTypes="this.transitStore.metaObjectTypes"
    :locations="this.transitStore.locations"/>
</template>

<script>
import { getIcon } from '@/utils/icons';
import useTransitStore from '@/stores/TransitStore';
import ObjectCard from './ObjectCard.vue';
import ObjectFilterPopUp from './ObjectFilterPopUp.vue';
import PaginationControl from './PaginationControl.vue';
import SearchBar from './SearchBar.vue';
import LoadingOverlay from './LoadingOverlay.vue';

export default {
  name: 'ObjectTab',
  components: {
    ObjectCard,
    PaginationControl,
    ObjectFilterPopUp,
    SearchBar,
    LoadingOverlay,
  },
  data() {
    return {
      filterIcon: getIcon('filterIcon'),
      showFilter: false,
      elementsPerPage: 50,
      currentPage: 1,
      totalItems: 0,
      isLoading: false,
      sessionFilters: {},
      searchParam: {},
    };
  },
  setup() {
    const transitStore = useTransitStore();
    return {
      transitStore,
    };
  },
  async mounted() {
    this.currentPage = 1;
    try {
      this.isLoading = true;
      await this.transitStore.fetchLocation();
      await this.transitStore.fetchMetaObjectTypes();
      await this.transitStore.fetchMetaObjects(this.getFetchParams());
      this.totalItems = this.transitStore?.metaObject?.count ?? 0;
    } catch (err) {
      this.totalItems = 0;
      console.error(err);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async onFilterUpdated(filters) {
      this.sessionFilters = { ...filters };
      sessionStorage.objectsFilter = JSON.stringify(this.sessionFilters);
      this.currentPage = 1;

      try {
        this.isLoading = true;
        await this.transitStore.fetchMetaObjects(this.getFetchParams());
        this.totalItems = this.transitStore?.metaObject?.count ?? 0;
      } catch (err) {
        this.totalItems = 0;
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
    handleElementsPerPageChange(value) {
      this.elementsPerPage = value;
      this.navigatePages();
    },
    async navigatePages() {
      const maxPage = Math.ceil(this.totalItems / this.elementsPerPage);
      if (this.currentPage > maxPage) {
        this.currentPage = 1;
      }
      try {
        this.isLoading = true;
        await this.transitStore.fetchMetaObjects(this.getFetchParams());
        this.totalItems = this.transitStore?.metaObject?.count ?? 0;
      } catch (err) {
        this.totalItems = 0;
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
    getFetchParams() {
      return {
        pageNumber: this.currentPage - 1,
        pageSize: this.elementsPerPage,
        metaObjectType: this.sessionFilters?.typeList || [],
        locations: this.sessionFilters?.locations || [],
        initialTime: this.sessionFilters?.fromDate || 0,
        finishTime: this.sessionFilters?.toDate || Math.floor(Date.now() / 1000),
        confidenceRange: this.sessionFilters?.confidenceRange,
        searchValue: this.sessionFilters?.searchValue || '',
      };
    },
  },
  created() {
    if (!sessionStorage.objectsFilter) {
      this.sessionFilters = {};
    } else {
      this.sessionFilters = Object(JSON.parse(sessionStorage.objectsFilter));
    }
  },
};
</script>
