<template>
  <WheelEventCapturer>
    <div v-if="show"
      ref="popupImageViewer"
      class="fixed inset-0 z-20 flex items-center justify-center bg-black bg-opacity-50"
      @click="closePopup()"
      @keyup.esc="closePopup()"
      tabindex="0">
      <ToastComponent ref="toastOnPopUp" class="mx-auto"/>
      <div
        class="bg-gray-200 relative p-5 w-4/5 overflow-auto flex flex-col h-[85%] rounded-xl"
        @click.stop>
        <button @click="closePopup()"
          class="absolute top-3 right-3 rounded-full bg-white hover:bg-gray-200 p-3"
          :title="this.$t('popUpImage.closePopup')">
          <img :src="getIcon('crossIcon')" class="w-3 h-3" alt="close button"/>
        </button>
        <div
          :class="['flex flex-row bg-white rounded-t-xl',
            this.transitSections.length > 1 ? 'h-[80%]' : 'h-full'
          ]">
          <div
            v-if="this.isFetchingImage"
            class="inline-flex flex-col w-full justify-center items-center bg-[#1e3c53] rounded-tl-xl rounded-br-xl">
            <div
              class="flex align-middle items-center justify-center h-fit w-fit mx-3
                p-4 border rounded-xl bg-sky-100 border-sky-500 text-sky-800">
              <p class="flex text-2xl font-bold gap-6 items-center">
                {{ this.$t('popUpImage.loadingImage')}}
                <img :src="getIcon('loadingGif')" class="w-16 h-16" alt="Loading data gif"/>
              </p>
            </div>
          </div>
          <div v-else
            class="h-full w-full bg-[#1e3c53] p-2 flex items-center justify-center rounded-tl-xl rounded-br-xl">
            <ImageMagnifier
              v-if="initialize() && thereAreImages() && !this.fetchError"
              :src="this.selectedImage"
              :detectionRoi="this.selectedImageData?.roi"/>
            <div v-else class="p-4 mt-4 border rounded bg-red-50 border-red-200 text-red-800">
              <p class="text-2xl font-bold">
                {{ this.fetchError }}
              </p>
            </div>
          </div>
          <div class="basis-1/5 h-full p-2">
            <CardSection
              class=""
              :section="selectedSection"
              :borderless="true"
              :sectionIndex=0
              @update-value="updateSectionValue"
            />
            <hr class="border-t-2 border-gray-300 my-2"/>
            <div class="grid grid-cols-3 cursor-default text-sm text-left w-auto gap-1 text-ellipsis overflow-hidden">
              <div class="">
                {{ this.$t('popUpImage.criteria') }}
              </div>
              <div class="col-span-2">
                <b class="break-keep text-center capitalize"
                v-if="this.selectedImageData.criteria">
                  {{ this.$t(`popUpImage.${this.selectedImageData.criteria}`) }}
                </b>
              </div>
              <div class="">
                {{ this.$t('popUpImage.from') }}
              </div>
              <div class="col-span-2 capitalize">
                <b>{{ this.selectedImageData.stream_alias }}</b>
              </div>
              <div class="">
                {{ this.$t('popUpImage.at') }}
              </div>
              <div class="col-span-2"><b>{{ getLocalDateTimeFromTimestamp(this.selectedImageData.time) }}</b></div>
            </div>
            <hr class="border-t-2 border-gray-300 my-2"/>
            <div class="text-left bg-gray-200 rounded-xl max-h-[425px] overflow-auto">
              <div class="text-sm bg-gray-300 rounded-t-xl font-semibold border-b-2 border-gray-400 p-2 pt-3">
                {{ this.$t('popUpImage.imagesByCriteria') }}
              </div>
              <div v-for="(image, index) in this.selectedSection.images" :key="image.criteria" class="relative">
                <button
                  :class="['capitalize p-1 w-full text-left text-xs min-h-11',
                          (index % 2 === 0) ? 'bg-gray-200' : 'bg-gray-100',
                          (this.selectedImageData === image) ? 'bg-sky-200 font-bold text-sky-900' : 'hover:bg-sky-100']"
                  @click="changeSelectedImage(image)">
                  <p>{{ this.$t(`popUpImage.${this.selectedImageData.criteria}`) }} - {{ this.$t(image.detectionTypeName) }}</p>
                  <p class="text-[10px] italic">({{ image.stream_alias}})</p>
                </button>
                <button v-on:click="downloadImage(image)"
                  class="absolute top-1 right-2 bg-white rounded-md w-fit h-fit px-2 py-1
                    transition-colors duration-200 ease-in-out transform hover:bg-gray-300 hover:shadow-lg
                    hover:scale-105">
                  <i><img
                        :src="getIcon('mediaDownloadIcon')"
                        alt="download picture icon"
                        title="Download image"
                        class="w-7 h-7"/>
                  </i>
                </button>
                <div
                  v-for="additionalImage in image.additionalStreamImages"
                  :key="additionalImage.criteria"
                  class="relative">
                  <button
                    :class="['flex capitalize p-1 w-full text-left text-xs pl-4 min-h-11',
                          (index % 2 === 0) ? 'bg-gray-200' : 'bg-gray-100',
                          'border-t border-dotted border-gray-400',
                          (this.selectedImageData === additionalImage) ? 'bg-sky-200 font-bold text-sky-900' : 'hover:bg-sky-100']"
                    @click="changeSelectedImage(additionalImage)">
                    <svg width="12px" height="12px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                    fill="#000000" transform="matrix(-1, 0, 0, 1, 0, 0)" alt="Additional stream">
                      <g id="SVGRepo_iconCarrier">
                        <path fill="none" stroke="#000000" stroke-width="2" d="M9,4 L4,9 L9,14 M18,19 L18,9 L5,9" transform="matrix(1 0 0 -1 0 23)">
                        </path>
                      </g>
                    </svg>
                    <p class="text-[10px] italic">
                      ({{ additionalImage.stream_alias }})
                    </p>
                  </button>
                  <button v-on:click="downloadImage(image)"
                    class="absolute top-1 right-2 bg-white rounded-md w-fit h-fit px-2 py-1
                      transition-colors duration-200 ease-in-out transform hover:bg-gray-300 hover:shadow-lg
                      hover:scale-105">
                    <i><img
                          :src="getIcon('mediaDownloadIcon')"
                          alt="download picture icon"
                          title="Download image"
                          class="w-7 h-7"/>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="this.transitSections.length > 1"
          :class="['min-h-[120px] flex gap-2 w-full bg-white rounded-b-xl overflow-auto p-2 h-[20%]']">
          <div
            v-for="(section, index) in this.transitSections"
            v-bind:key="index"
            :class="this.isTrain ? 'bg-gray-200 p-2 rounded-md place-content-end' : 'place-content-center'">
            <div class="flex gap-2 place-content-end h-full">
              <div
              class="section-element flex place-items-end h-full"
              :sectionId=section.section_id>
                <CardSection
                  v-on:click="section.images.length > 0 ? changeSelectedSection(section) : ''"
                  :class="['h-full px-1 pb-1 pt-0 rounded-md m-0 border border-gray-200 cursor-pointer',
                    section === this.selectedSection ? 'bg-sky-200 font-bold text-sky-900' : 'bg-white hover:bg-sky-100',
                    section.images.length <= 0 ? 'hover:bg-white text-gray-400 hover:cursor-default': '']"
                  :section="section"
                  :borderless=true
                  :reduced=true
                  :editable=false
                  :sectionIndex=index
                  @update-value="updateSectionValue"/>
              </div>
              <div
                v-for="(child_meta_object, child_meta_object_index) in section.childMetaObjects"
                v-bind:key="child_meta_object_index"
                class="section-element h-full"
                :sectionId=child_meta_object.section_id>
                <CardSection
                  v-on:click="child_meta_object.images.length > 0 ? changeSelectedSection(child_meta_object) : ''"
                  :class="['h-full px-1 pb-1 pt-0 rounded-md m-0 border border-gray-200 cursor-pointer',
                    child_meta_object === this.selectedSection ? 'bg-sky-200 font-bold text-sky-900' : 'bg-white hover:bg-sky-100',
                    child_meta_object.images.length <= 0 ? 'hover:bg-white text-gray-400 hover:cursor-default': '']"
                  :section="child_meta_object"
                  :borderless=true
                  :reduced=true
                  :editable=false
                  :sectionIndex=child_meta_object_index
                  @update-value="updateSectionValue"/>
              </div>
            </div>
            <div
              class="flex"
              v-if="this.isTrain">
              <img :src='wagonLeftSide' class="w-4 h-4" alt="Wagon left side"/>
              <img :src='wagonCenter' class="w-full h-4" alt="Wagon center"/>
              <img :src='wagonRightSide' class="w-4 h-4" alt="Wagon right side"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </WheelEventCapturer>
</template>

<script>
import { ref } from 'vue';
import { getIcon } from '@/utils/icons';
import { getStatisticalTypeName } from '@/utils/lookup';

import useTransitStore from '@/stores/TransitStore';
import wagonLeftSide from '@/assets/img/wagon_left_side.png';
import wagonRightSide from '@/assets/img/wagon_right_side.png';
import wagonCenter from '@/assets/img/wagon_center.png';
import closeButton from '@/assets/img/close-button.png';
import { getLocalDateTimeFromTimestamp } from '@/utils/date';
import WheelEventCapturer from '@/components/generic/WheelEventCapturer.vue';
import ToastComponent from '@/components/generic/ToastComponent.vue';
import CardSection from '@/components/CardSection.vue';
import ImageMagnifier from '@/components/ImageMagnifier.vue';

export default {
  props: ['show', 'transitSections', 'isTrain'],
  name: 'PopupImage',
  components: {
    CardSection,
    ToastComponent,
    ImageMagnifier,
    WheelEventCapturer,
  },
  setup() {
    const thisElement = ref();
    return {
      transitStore: useTransitStore(),
      getLocalDateTimeFromTimestamp,
      thisElement,
      getIcon,
      getStatisticalTypeName,
      wagonLeftSide,
      wagonRightSide,
      wagonCenter,
    };
  },
  data() {
    return {
      closeButton,
      initialized: false,
      isFetchingImage: false,
      selectedSection: {},
      selectedImage: '',
      selectedImageBlob: '',
      selectedImageData: {},
      hover: false,
      fetchError: '',
    };
  },
  methods: {
    initialize() {
      if (!this.initialized) {
        [this.selectedSection] = this.transitSections;
        if (this.selectedSection.images.length) {
          this.changeSelectedImage(this.selectedSection.images[0]);
        } else {
          this.fetchError = this.$t('popUpVideo.noAvailableVideos');
        }
        this.initialized = true;
      }
      return true;
    },
    uninitialize() {
      if (this.selectedSection) {
        URL.revokeObjectURL(this.selectedImageBlob);
        this.selectedSection = '';
        this.selectedImageData = {};
        this.selectedImage = '';
      }
      if (this.tryReloadVideoTimerId) {
        clearTimeout(this.tryReloadVideoTimerId);
        this.tryReloadVideoTimerId = null;
      }
      this.fetchError = '';
      this.initialized = false;
      this.isFetchingImage = false;
    },
    thereAreImages() {
      return (this.selectedSection?.images?.length > 0);
    },
    // eslint-disable-next-line no-unused-vars
    async updateSectionValue({ index, updatedValue, sectionIndex }) {
      const currentSection = this.selectedSection.values[index];
      if (currentSection.value === updatedValue) return;
      let putResult = false;
      if (currentSection.value_type === 'reading') {
        putResult = await this.transitStore.putReadingValue({ readingId: currentSection.db_id, newValue: updatedValue });
      } else {
        putResult = await this.transitStore.putClassificationValue({ classificationId: currentSection.db_id, newValue: updatedValue });
      }
      if (putResult) {
        currentSection.edited = true;
        currentSection.value = updatedValue;
        this.$refs.toastOnPopUp.openToast({ toastType: 'info', toastText: this.$t('transitCard.successfulEdit') });
      } else {
        const replaceValue = currentSection.value;
        currentSection.value = replaceValue;
        this.$refs.toastOnPopUp.openToast({ toastType: 'error', toastText: this.$t('transitCard.errorOnEdit') });
      }
    },
    changeSelectedSection(newSelectedSection) {
      this.selectedSection = newSelectedSection;
      this.changeSelectedImage(this.selectedSection.images[0]);

      const lastSectionElement = this.$el.querySelector(`.section-element[sectionId="${this.selectedSection.section_id}"]`);
      if (lastSectionElement) {
        lastSectionElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      }
    },
    async changeSelectedImage(newSelectedImageData) {
      if (!newSelectedImageData) {
        URL.revokeObjectURL(this.selectedImageBlob);
        this.selectedImageData = {};
        this.fetchError = this.$t('popUpImage.errorDownloadingImage');
        return;
      }
      if (this.selectedImageData !== newSelectedImageData) {
        URL.revokeObjectURL(this.selectedImageBlob);
        this.selectedImageData = newSelectedImageData;
        try {
          this.selectedImageBlob = await this.transitStore.fetchImageInline(
            this.selectedImageData?.stream_id,
            this.selectedImageData?.id,
          );
          this.selectedImage = URL.createObjectURL(this.selectedImageBlob);
          this.fetchError = '';
        } catch (error) {
          this.fetchError = this.$t('popUpImage.errorDownloadingImage');
          this.$refs.toastOnPopUp.openToast({ toastType: 'error', toastText: this.$t('popUpImage.errorDownloadingImage') });
        }
      }
    },
    async downloadImage(pressedImageData) {
      try {
        const data = await this.transitStore.fetchImage(
          pressedImageData?.stream_id,
          pressedImageData?.id,
        );
        if (data) {
          const imageBlob = URL.createObjectURL(data.blob);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.style = 'display: none';
          a.href = imageBlob;
          a.download = data.filename;
          a.click();
          a.remove();
          URL.revokeObjectURL(imageBlob);
        } else {
          this.fetchError = this.$t('popUpImage.errorDownloadingImage');
          this.$refs.toastOnPopUp.openToast({ toastType: 'error', toastText: this.$t('popUpImage.errorDownloadingImage') });
        }
      } catch (error) {
        this.fetchError = this.$t('popUpImage.errorDownloadingImage');
        this.$refs.toastOnPopUp.openToast({ toastType: 'error', toastText: this.$t('popUpImage.errorDownloadingImage') });
      }
    },
    closePopup() {
      this.uninitialize();
      this.$emit('close');
    },
    takeFocusIfShown() {
      if (this.show) {
        this.$nextTick(() => this.$refs.popupImageViewer.focus());
      }
    },
  },
  watch: {
    show: 'takeFocusIfShown',
  },
};
</script>
