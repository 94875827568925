<template>
  <div class="relative" @focusin="hasFocus=true" @focusout="hasFocus=false">
    <div class="inline-flex items-center">
      <button id="titleButton" :class="[
        'flex h-8 items-center border bg-white hover:bg-gray-100 break-keep min-w-fit',
        'py-1 rounded-lg pr-8 relative disabled:border-none disabled:bg-transparent disabled:cursor-not-allowed',
        (!leftButtonIconName) ? 'pl-2' : '']"
        :disabled="this.disabled"
        @click="toggleDropdown">
          <img v-if="leftButtonIconName !== ''"
            :src="getComboIcon(leftButtonIconName)"
            :class="['mx-1 w-5 h-5',
            leftButtonIconStyleInjection,
            ]"
            alt="multi-select-combo icon"/>
        {{ buttonText }}
        <svg v-if="!this.disabled" class="absolute ml-1 right-2" width="20px" height="20px" viewBox="0 0 24 24" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path v-if="dropdownHidden" fill-rule="evenodd" clip-rule="evenodd" d="M12.7071 14.7071C12.3166 15.0976 11.6834 15.0976
                11.2929 14.7071L6.29289 9.70711C5.90237 9.31658 5.90237 8.68342 6.29289 8.29289C6.68342 7.90237
                7.31658 7.90237 7.70711 8.29289L12 12.5858L16.2929 8.29289C16.6834 7.90237 17.3166 7.90237 17.7071
                8.29289C18.0976 8.68342 18.0976 9.31658 17.7071 9.70711L12.7071 14.7071Z" fill="#000000" />
          <path v-else fill-rule="evenodd" clip-rule="evenodd" d="m 12.7071,8.292875 c -0.3905,-0.3905 -1.0237,-0.3905 -1.4142,0 l
                -5.00001,4.99999 c -0.39052,0.39053 -0.39052,1.02369 0,1.41422 0.39053,0.39052 1.02369,0.39052 1.41422,0 L 12,10.414175
                l 4.2929,4.29291 c 0.3905,0.39052 1.0237,0.39052 1.4142,0 0.3905,-0.39053 0.3905,-1.02369 0,-1.41422 z"
            fill="#000000" />
        </svg>
      </button>
      <div id="dropdown" v-show="!dropdownHidden"
        :class="['absolute top-9 rounded-md shadow-lg bg-white cursor-pointer z-40',
        this.comboOptions.length > 10 ? ' right-0 overflow-y-scroll max-h-[450px]' : '']">
        <div v-for="optionValue in this.comboOptions" :key="optionValue.id">
          <button
            class="flex items-center text-left capitalize text-gray-700
                  hover:bg-gray-100 hover:rounded-md hover:text-gray-900
                  w-full p-2 overflow-hidden truncate hover:overflow-auto"
            @click="optionToggled(optionValue)">
            <i class="w-4 h-4"><img :src="getComboIcon(this.selectedComboOption.id === optionValue.id ? 'radioButtonSelected' : 'radioButton')"
              class="w-4 h-4 ml-1 mr-2" alt="checkbox icon" /></i>
            <span class="pl-3">{{ optionValue.value.replaceAll('_', ' ') }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getIcon } from '@/utils/icons';

export default {
  name: 'SingleSelectCombo',
  props: {
    uniqueComboName: {
      type: String,
      required: true,
    },
    comboOptions: {
      type: Object,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    leftButtonIconName: {
      type: String,
      default: '',
      required: false,
    },
    leftButtonIconStyleInjection: {
      type: String,
      required: false,
    },
    restoreFromSessionStorage: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    const getComboIcon = getIcon;
    return {
      dropdownHidden: true,
      comboOptionId: null,
      selectedComboOption: {},
      getComboIcon,
      hasFocus: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.emitter.emit('close-other-combos', this);
      this.dropdownHidden = !this.dropdownHidden;
    },
    closeDropdown() {
      this.dropdownHidden = true;
    },
    optionToggled(newOption) {
      if (this.selectedComboOption?.id !== newOption.id) {
        this.selectedComboOption = newOption;
        if (this.restoreFromSessionStorage) {
          sessionStorage.setItem(this.uniqueComboName, JSON.stringify(this.selectedComboOption));
        }
        this.$emit('comboSelectionChanged', this.selectedComboOption);
        this.dropdownHidden = true;
      }
    },
    restoreSessionData() {
      if (this.restoreFromSessionStorage) {
        this.selectedComboOption = JSON.parse(sessionStorage.getItem(this.uniqueComboName));
        if (!this.selectedComboOption) {
          const [firstOption] = this.comboOptions;
          this.selectedComboOption = firstOption;
        }
      }
    },
    setPreselectedOption(preselectedOption) {
      this.selectedComboOption = preselectedOption;
    },
  },
  mounted() {
    this.emitter.on('close-other-combos', (emitterCombo) => {
      if (emitterCombo !== this) {
        this.closeDropdown();
      }
    });
    this.restoreSessionData();
  },
  unmounted() {
    this.emitter.off('close-other-combos');
  },
  watch: {
    comboOptions: 'restoreSessionData',
  },
};
</script>
