<template>
  <div v-if="this.show" class="flex absolute w-full h-full bg-[#1e3c53] z-50">
    <div class="bg-gray-100 m-5 rounded-3xl p-4 w-1/3 flex flex-col items-center m-auto place-self-center">
      <div class="text-2xl">{{ this.$t(this.singleUsePassword? 'resetPassword.resetTitle': 'resetPassword.changeCurrentTitle') }}</div>
      <hr class="w-full border-t-1 border border-gray-300 mt-2 mb-2"/>
      <div class="text-sm flex flex-col justify-center items-center">
        <p>{{ this.$t('resetPassword.newPasswordMustContain') }}</p>
        <ul class="list-disc text-xs">
          <li>{{ this.$t('resetPassword.oneNumber') }}</li>
          <li>{{ this.$t('resetPassword.oneUppercase') }}</li>
          <li>{{ this.$t('resetPassword.oneLowercase') }}</li>
          <li>{{ this.$t('resetPassword.charactersLength') }}</li>
        </ul>
      </div>
      <form action="#" @submit.prevent="submitForm" class="w-3/4 mb-5">
        <!-- input hidden, needed to avoid chrome acccessibility warning -->
        <label class="h-0 p-0 m-0" display="hidden"><input type="text" autocomplete="username" hidden="true"></label>
        <div class="flex flex-col gap-2 mb-5 mt-4">
          <InputPassword v-if="!this.singleUsePassword" ref="current_password" id="current_password"
            :placeholder="this.$t('resetPassword.currentPasswordPlaceholder')"
            :passwordLabel="this.$t('resetPassword.currentPasswordLabel')"
            v-on:keydown.enter.prevent="this.submitForm"/>
          <InputPassword ref="new_password" id="new_password"
            :placeholder="this.$t('resetPassword.newPasswordPlaceholder')"
            :passwordLabel="this.$t('resetPassword.newPasswordLabel')"
            v-on:keydown.enter.prevent="this.submitForm"/>
          <InputPassword ref="repeat_password" id="repeat_password"
            :placeholder="this.$t('resetPassword.repeatPasswordPlaceholder')"
            :passwordLabel="this.$t('resetPassword.repeatPasswordLabel')"
            v-on:keydown.enter.prevent="this.submitForm"/>
        </div>
        <hr v-if="this.errorMessage" class="border-t-1 border-dashed border-gray-300 mt-4 mb-4"/>
        <p v-if="this.errorMessage" class="mb-4 text-red-700 text-sm">{{ this.errorMessage }}</p>
        <div :class="['flex gap-6',
          this.singleUsePassword ? 'justify-center' : 'justify-between items-center']">
          <button v-if="!this.singleUsePassword" class="mt-2 py-2 text-gray-600 hover:font-bold
            underline underline-offset-4 hover:decoration-4 hover:text-rose-950"
            @click="this.$emit('change-password-cancelled')">
            {{ $t('resetPassword.cancel') }}
          </button>
          <button type="submit" class="group relative w-fit h-fit flex items-center p-2
          text-sm font-medium rounded-md text-white
          bg-sky-500 hover:bg-sky-700 focus:outline-none focus:ring-2
          focus:ring-offset-2 focus:ring-sky-500 disabled:bg-[#83878a]">
          {{ this.$t('resetPassword.submitButtonText') }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import useTransitStore from '@/stores/TransitStore';
import useUserManagementStore from '@/stores/UserManagementStore';
import { thereIsClientLogo } from '@/../TransitViewerConfig';
import showEyeImage from '@/assets/img/show-eye.png';
import hideEyeImage from '@/assets/img/hide-eye.png';
import { getIcon } from '@/utils/icons';
import InputPassword from '@/components/InputPassword.vue';

export default {
  name: 'ResetPassword',
  props: ['show', 'singleUsePassword'],
  components: {
    InputPassword,
  },
  setup() {
    const transitStore = useTransitStore();
    const userManagementStore = useUserManagementStore();
    return {
      transitStore,
      userManagementStore,
    };
  },
  data() {
    return {
      appLogo: getIcon('applicationLogo'),
      showEyeImage,
      hideEyeImage,
      showPassword: false,
      errorMessage: '',
      currentPassword: '',
    };
  },
  methods: {
    checkChangedPassword(currentPassword, newPassword, repeatPassword) {
      if (currentPassword === newPassword) {
        this.errorMessage = this.$t('resetPassword.newPasswordDifferentFromOld');
        return false;
      }
      if (newPassword !== repeatPassword) {
        this.errorMessage = this.$t('resetPassword.newPasswordAndRepetitionDontMatch');
        return false;
      }
      this.errorMessage = '';
      return true;
    },
    async submitForm() {
      const newPassword = this.$refs.new_password.getPasswordValue();
      const repeatPassword = this.$refs.repeat_password.getPasswordValue();
      if (this.singleUsePassword) {
        if (!this.checkChangedPassword(this.currentPassword, newPassword, repeatPassword)) {
          return;
        }
        const passwordResetResult = await this.userManagementStore.resetPassword(this.currentPassword, newPassword);
        if (passwordResetResult) {
          const authMethod = JSON.parse(localStorage.dashboardAuthMethod);
          this.username = this.userManagementStore.getEmail();
          await this.userManagementStore.handleSignIn(this.username, newPassword, authMethod);
          this.$router.push({ path: '/' });
        }
      } else {
        const currentPassword = this.$refs.current_password.getPasswordValue();
        if (!this.checkChangedPassword(currentPassword, newPassword, repeatPassword)) {
          return;
        }
        await this.userManagementStore.changePassword(currentPassword, newPassword)
          .then(() => {
            this.emitter.emit('show-toast', { toastType: 'info', toastText: this.$t('accountInfo.passwordChanged') });
            this.$emit('password-changed');
          })
          .catch(() => { this.errorMessage = this.$t('accountInfo.currentPasswordFailed'); });
      }
    },
  },
  async mounted() {
    if (thereIsClientLogo()) {
      const clientLogo = await this.transitStore.fetchClientLogo();
      if (clientLogo) {
        this.appLogo = clientLogo;
      }
    }
  },
  watch: {
    singleUsePassword() {
      this.currentPassword = this.singleUsePassword;
    },
  },
};
</script>
