import { defineStore } from 'pinia';
import {
  getAPIData,
  putAPIData,
  postAPIData,
  deleteAPIData,
} from '@/utils/api';
import i18n from '@/locale/i18n';
import emitter from '@/utils/create_emitter';

export const REFRESH_INTERVAL = 300000; /* 5 minutes */
const REFRESH_TOKEN_MAX_RETRIES = 2;

const userManagementStore = defineStore('userManagementStore', {
  state: () => ({
    userManagement: {
      privateSessionInfo: {},
      privateAccountInfo: {},
    },
    users: [],
    roles: [],
    userActivity: true,
    refreshTokenJobId: -1,
    refreshTokenRetries: 0,
  }),
  actions: {
    setSignInInfo(signInInfo) {
      this.userManagement.privateSessionInfo.token = signInInfo.token;
      this.userManagement.privateAccountInfo.id = signInInfo.user?.id;
      this.userManagement.privateAccountInfo.name = signInInfo.user?.name;
      this.userManagement.privateAccountInfo.surname = signInInfo.user?.surname;
      this.userManagement.privateAccountInfo.email = signInInfo.user?.email;
      this.userManagement.privateSessionInfo.isReset = signInInfo.is_reset;
      this.userManagement.privateAccountInfo.permissions = this.getPermissionsFromToken(signInInfo.token);
      sessionStorage.userManagement = JSON.stringify(this.userManagement);
    },
    getUserToken() {
      return this.userManagement.privateSessionInfo?.token;
    },
    getUserId() {
      return this.userManagement.privateAccountInfo.id;
    },
    getUserName() {
      return this.userManagement.privateAccountInfo.name;
    },
    getUserSurname() {
      return this.userManagement.privateAccountInfo.surname;
    },
    getEmail() {
      return this.userManagement.privateAccountInfo.email;
    },
    hasPermissionName(permissionName) {
      return this.userManagement?.privateAccountInfo?.permissions?.some((permission) => permission === permissionName);
    },
    hasFacilitiesWritePermission() {
      return this.hasPermissionName('facilities_write');
    },
    hasAllUsersWritePermission() {
      return this.hasPermissionName('all_users_write');
    },
    signOut() {
      this.userManagement.privateSessionInfo = {};
      this.userManagement.privateAccountInfo = {};
      sessionStorage.userManagement = JSON.stringify(this.userManagement);
    },
    isSignedIn() {
      if (Object.keys(this.userManagement.privateSessionInfo).length === 0
      && sessionStorage.userManagement) {
        this.userManagement = JSON.parse(sessionStorage.userManagement);
      }

      return this.userManagement.privateSessionInfo.token !== undefined
        && this.userManagement.privateAccountInfo.id !== undefined
        && !Number.isNaN(this.userManagement.privateAccountInfo.id);
    },
    setAccountInfo(accountInfo) {
      this.userManagement.privateAccountInfo.name = accountInfo.name;
      this.userManagement.privateAccountInfo.surname = accountInfo.surname;
      this.userManagement.privateAccountInfo.email = accountInfo.email;
      sessionStorage.userManagement = JSON.stringify(this.userManagement);
    },
    getAccountInfo() {
      return this.userManagement.privateAccountInfo;
    },
    setUsers(fetchedUsers) {
      this.users = [...this.getPrepareUsersList(fetchedUsers)];
    },
    getPrepareUsersList(fetchedUsers) {
      const usersList = [];
      fetchedUsers.forEach((fetchedUser) => {
        const newuser = { ...fetchedUser };
        const [userRole] = fetchedUser.roles;
        delete newuser.roles;
        newuser.role = userRole;
        usersList.push(newuser);
      });
      return usersList;
    },
    getUsers() {
      return this.users;
    },
    setUserRoles(rolesList) {
      this.roles = [];
      rolesList.forEach((role) => this.roles.push({
        id: role.id,
        value: i18n.global.t(`userRoleSelector.${role.name}`),
        name: role.name,
      }));
    },
    getUserDataFromToken(token) {
      if (!token) {
        return {};
      }
      let base64Url = token.split('.')[1];
      base64Url = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      return JSON.parse(window.atob(base64Url))?.userData;
    },
    getPermissionsFromToken() {
      const userData = this.getUserDataFromToken(this.userManagement.privateSessionInfo.token);
      if (!userData) {
        return {};
      }
      return userData.permissions;
    },
    isUserReset() {
      return this.userManagement.privateSessionInfo.isReset;
    },
    async addUser(userInfo) {
      return postAPIData('/users', this.getUserToken(), { body: JSON.stringify(userInfo) });
    },
    async editUser(userInfo) {
      const addUserEndpointUrl = `/users/${userInfo.id}`;
      return putAPIData(addUserEndpointUrl, this.getUserToken(), { body: JSON.stringify(userInfo) });
    },
    async deleteUser(userId) {
      return deleteAPIData(`/users/${userId}`, this.getUserToken())
        .then((response) => {
          const deleteSuccessfull = response.ok && response.no_content;
          if (deleteSuccessfull) {
            emitter.emit('user_deleted');
          }
          return deleteSuccessfull;
        });
    },
    async fetchRoles() {
      await getAPIData('/roles', this.getUserToken())
        .then((response) => this.setUserRoles(response.content))
        .catch();
    },
    async putUserRole(userId, roleIds) {
      return putAPIData(`/users/${userId}/roles`, this.getUserToken(), { body: JSON.stringify({ role_ids: roleIds }) })
        .then((response) => response.ok).catch(() => false);
    },
    async fetchUsers() {
      await getAPIData('/users', this.getUserToken()).then((response) => { this.setUsers(response.content); }).catch();
    },
    async fetchFilteredUsers(filterValues) {
      const queryParams = new URLSearchParams();
      if (filterValues.searchValue) {
        queryParams.append('search_value', filterValues.searchValue);
      }
      return getAPIData(`/users?${queryParams.toString()}`, this.getUserToken())
        .then((response) => this.getPrepareUsersList(response.content)).catch(() => []);
    },
    async handleSignIn(username, passwd, authMethod) {
      const bodyData = {
        user: username,
        password: passwd,
      };
      const url = authMethod === 'active_directory' ? '/auth/login_ldap' : '/auth/login';
      return postAPIData(url, this.getUserToken(), { body: JSON.stringify(bodyData) })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Wrong login credentials or other login error');
          }
          this.setSignInInfo(response.content);
          return this.signInfo;
        });
    },
    async fetchAccountInfo() {
      let accountInfo = {};
      if (this.isSignedIn()) {
        await getAPIData(`/users/${this.getUserId()}`, this.getUserToken())
          .then((response) => {
            if (response.ok) {
              accountInfo = response.content;
              this.setAccountInfo(accountInfo);
            }
          }).catch();
      }
      return accountInfo;
    },
    async handleUserData(accountInfo) {
      return putAPIData(`/users/${this.getUserId()}`, this.getUserToken(), { body: JSON.stringify(accountInfo) })
        .then((response) => {
          this.setAccountInfo({
            email: accountInfo.email,
            name: accountInfo.name,
            surname: accountInfo.surname,
          });
          return response.content;
        });
    },
    async requestSingleUsePassword(userId) {
      return putAPIData(`/users/${userId}/single-use-password`, this.getUserToken())
        .then((response) => response.content?.single_use_password)
        .catch(() => '');
    },
    async resetPassword(singleUsePassword, newPassword) {
      const requestBody = {
        single_use_password: singleUsePassword,
        new_password: newPassword,
      };
      return putAPIData(`/users/${this.getUserId()}/reset-password`, this.getUserToken(), { body: JSON.stringify(requestBody) })
        .then((response) => response.ok && response.no_content).catch(() => false);
    },
    async changePassword(currentPassword, newPassword) {
      const requestBody = {
        current_password: currentPassword,
        new_password: newPassword,
      };
      return putAPIData(`/users/${this.getUserId()}/password`, this.getUserToken(), { body: JSON.stringify(requestBody) }).catch(() => null);
    },
    setUserActivityOn() {
      this.userActivity = true;
    },
    async refreshTokenIfUserActivity() {
      if (this.userActivity) {
        this.refreshToken();
        this.userActivity = false;
        this.refreshTokenRetries = 0;
      } else if (this.refreshTokenRetries >= REFRESH_TOKEN_MAX_RETRIES) {
        this.closeSession();
      } else {
        console.warn('No user activity during 5 minutes. Retrying...');
        this.refreshTokenRetries += 1;
      }
    },
    startRefreshTokenPeriodicJob() {
      this.refreshTokenJobId = setInterval(this.refreshTokenIfUserActivity, REFRESH_INTERVAL);
    },
    stopRefreshTokenPeriodicJob() {
      clearInterval(this.refreshTokenJobId);
    },
    async refreshToken() {
      await getAPIData('/auth/refresh_token', this.getUserToken())
        .then((response) => {
          this.userManagement.privateSessionInfo.token = response.content;
          sessionStorage.userManagement = JSON.stringify(this.userManagement);
        })
        .catch(() => {
          this.closeSession();
        });
    },
    closeSession() {
      console.warn('Closing session due to innactivity');
      window.dispatchEvent(new Event('user-token-expired'));
      this.refreshTokenRetries = 0;
    },
  },
});

export default userManagementStore;
