<template>
  <MultiSelectCombo ref="multiSelectCombo" :buttonText="this.$t('transitTypeFilter.transitType')" :uniqueComboName="this.uniqueComboName"
    :comboOptions="this.transitTypeArray" leftButtonIconName="transitIcon" signalOnChange="update:filterByTransitType"
    leftButtonIconStyleInjection="w-4 h-4"/>
</template>

<script>
import useTransitStore from '@/stores/TransitStore';
import MultiSelectCombo from './generic/MultiSelectCombo.vue';

export default {
  name: 'TransitTypeFilter',
  components: {
    MultiSelectCombo,
  },
  data() {
    return {
      transitTypeArray: [],
    };
  },
  props: {
    uniqueComboName: {
      type: String,
      required: true,
    },
  },
  methods: {
    computeTransitTypeArray(locations) {
      this.transitTypeArray = [];
      const transitTypeSet = new Set();
      Object.values(locations).forEach((location) => {
        if (!transitTypeSet.has(location.transit_type)) {
          transitTypeSet.add(location.transit_type);
          this.transitTypeArray.push({ id: location.transit_type, value: this.$t(`transitTypeFilter.${location.transit_type}`) });
        }
      });
    },
    selectAllOptions() {
      this.$refs.multiSelectCombo.selectAll();
    },
  },
  mounted() {
    this.emitter.on('locations-updated', (locations) => {
      this.computeTransitTypeArray(locations);
    });
    this.computeTransitTypeArray(useTransitStore().locations);
  },
  unmounted() {
    this.emitter.off('locations-updated');
  },
};
</script>
