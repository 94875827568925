<template>
  <div class="bg-white rounded-2xl px-5 py-3 w-full shadow relative">
    <IconDropdownMenu
      v-if="this.currentSourceInfo && this.userManagementStore.hasFacilitiesWritePermission()"
      class="absolute top-2 end-2 min-w-max h-14"
      :comboOptions="this.dropdownOptions"
      :notShowIfSelected=false
      buttonIcon="kebabIcon"
      @update:selection-changed="showDeleteSourceWarning"/>
    <div v-if="this.currentSourceInfo" class="absolute top-5 left-16 flex flex-col gap-2 align-center items-center">
      <i class="min-w-max h-[70px]">
        <img
          :src="this.camIcon"
          class="w-[70px] h-[70px] px-1 opacity-95 border border-gray-100 rounded shadow-md"
          alt="camera icon">
      </i>
      <div>
        <p class="text-xs">
          {{ this.$t('sourceInfoViewCard.created') }} {{ getLocalDateTimeFromTimestamp(this.currentSourceInfo.created_at) }}
        </p>
        <p class="text-xs">
          {{ this.$t('sourceInfoViewCard.updated') }} <b>{{ getLocalDateTimeFromTimestamp(this.currentSourceInfo.updated_at) }}</b>
        </p>
      </div>
    </div>
    <div class="flex flex-col items-centered justify-center py-3">
      <div class="grid grid-cols-2 gap-1">
        <p class="place-self-end p-1 self-center">{{ $t('sourceInfoViewCard.sourceAlias') }}:</p>
        <EditableField
          :fieldId="`sourceAlias${this.currentSourceInfo?.id}`"
          :fieldValidator="validateAlias"
          :nonEditable="!this.userManagementStore.hasFacilitiesWritePermission()"
          :value="this.sourceInfo.alias"
          :placeholder="this.$t('sourceInfoViewCard.sourceAliasPlaceholder')"/>
        <p class="place-self-end p-1 self-center">{{ $t('sourceInfoViewCard.sourceName') }}:</p>
        <EditableField
          :fieldId="`sourceName${this.currentSourceInfo?.id}`"
          :fieldValidator="validateName"
          :value="this.sourceInfo.name"
          :nonEditable="!this.userManagementStore.hasFacilitiesWritePermission()"
          :placeholder="this.$t('sourceInfoViewCard.sourceNamePlaceholder')"/>
        <p class="place-self-end p-1 self-center">{{ $t('sourceInfoViewCard.sourceUrl') }}:</p>
        <EditableField
          :fieldId="`sourceUrl${this.currentSourceInfo?.id}`"
          :fieldValidator="validateUrl"
          :value="this.sourceInfo.url"
          :nonEditable="!this.userManagementStore.hasFacilitiesWritePermission()"
          :placeholder="this.$t('sourceInfoViewCard.sourceUrlPlaceholder')"/>
        <p class="place-self-end p-1 self-center">{{ $t('sourceInfoViewCard.sourceDescription') }}:</p>
        <EditableField
          :fieldId="`sourceDescription${this.currentSourceInfo?.id}`"
          :fieldValidator="validateDescription"
          :value="this.sourceInfo.description"
          :nonEditable="!this.userManagementStore.hasFacilitiesWritePermission()"
          :placeholder="this.$t('sourceInfoViewCard.sourceDescriptionPlaceholder')"/>
        <p class="place-self-end py-3 px-1 items-center">{{ this.$t('sourceInfoViewCard.resizeLabel') }}</p>
        <div :class="['flex place-self-start items-center self-center gap-2',
          this.userManagementStore.hasFacilitiesWritePermission() ? '' : 'cursor-not-allowed']">
          <div class="flex gap-1">
            <button
              v-for="(resizeObject, index) in imageResizePossibleValues"
              v-bind:key="index"
              v-on:click="setResizeValue(resizeObject)"
              :class="['flex w-12 h-12 hover:bg-gray-200 justify-center rounded-lg border',
                this.userManagementStore.hasFacilitiesWritePermission() ? '' : 'pointer-events-none',
                resizeObject.value === this.sourceInfo.image_pyrdown ? 'border-[#1e3c53] border-dashed' : 'border-gray-200']"
              :title="resizeObject.label">
              <img
                alt="image resize icon"
                :class="[resizeObject.iconSize, 'self-center content-center']"
                :src="getIcon(resizeObject.value === this.sourceInfo.image_pyrdown ? 'imageSelectedIcon' : 'imageUnselectedIcon')"/>
            </button>
          </div>
          {{ imageResizePossibleValues[this.sourceInfo.image_pyrdown].label }}
        </div>
        <p class="place-self-end px-1 items-center">{{ this.$t('sourceInfoViewCard.qualityLabel') }}</p>
        <div
          :class="['flex', this.userManagementStore.hasFacilitiesWritePermission() ? '' : 'cursor-not-allowed']">
          <label htmlfor="quality-range"
            :class="['flex justify-center items-center gap-2 place-self-start',
            this.userManagementStore.hasFacilitiesWritePermission() ? '' : 'pointer-events-none',
            this.sourceInfo.image_quality > 50 ? 'text-black' : 'text-red-800']">
            <input id="quality-range" type="range" min=1 max=100
              v-model="this.sourceInfo.image_quality"
              v-on:mousedown="this.presetQualityValue()"
              v-on:mouseup="this.validateQuality()">
              {{ this.sourceInfo.image_quality }}%
          </label>
        </div>
        <p class="place-self-end p-1 items-center">{{ $t('sourceInfoViewCard.sourceLocations') }}:</p>
        <SelectorWithTags
          :editable="this.userManagementStore.hasFacilitiesWritePermission()"
          :availableOptions="this.locations"
          :preselectedOptions="this.selectedLocations"
          buttonIcon="locationPlusIcon"
          buttonItemIcon="locationIcon"
          @selection-changed="validateLocations" />
      </div>
      <hr v-if="this.errorMessage" class="border-t-1 border-dashed border-gray-300 mt-4 mb-4"/>
      <pre v-if="this.errorMessage" class="mt-2 text-red-700 text-sm">{{ this.errorMessage }}</pre>
    </div>
    <hr v-show="this.areFieldsEdited || !this.currentSourceInfo" class="border-t-1 border-dashed border-gray-300 mt-2 mb-1"/>
    <div class="flex gap-6 justify-center" v-show="this.areFieldsEdited || !this.currentSourceInfo">
      <button @click="cancelSourceAddOrEdit"
        class="mt-2 px-4 py-2 text-gray-600 hover:font-bold underline underline-offset-4 hover:decoration-4 hover:text-rose-950">
        {{ $t('locationInfoViewCard.cancelChangesButton') }}
      </button>
      <button @click="showSourceChangedNameWarningIfNecessary"
        class="mt-2 px-3 py-1 bg-sky-500 text-white rounded
          hover:bg-sky-700 transition-colors duration-300 disabled:bg-gray-300"
          :disabled="!this.submitButtonEnabled">
          {{ $t('facilityInfoViewCard.applyChangesButton') }}
      </button>
    </div>
    <ModalDialog
      ref="sourceInfoViewCardModal"
      @accept-change="submitSource"
      @cancel-change="cancelSourceAddOrEdit"
      @delete-source="deleteSource"
      @reset-quality-value="resetQualityValue"/>
  </div>
</template>

<script>
import useTransitStore from '@/stores/TransitStore';
import useUserManagementStore from '@/stores/UserManagementStore';
import EditableField from '@/components/generic/EditableField.vue';
import ModalDialog from '@/components/generic/ModalDialog.vue';
import IconDropdownMenu from '@/components/generic/IconDropdownMenu.vue';
import SelectorWithTags from '@/components/generic/SelectorWithTags.vue';
import isValidUrl from '@/utils/urls';
import { getIcon } from '@/utils/icons';
import { getLocalDateTimeFromTimestamp } from '@/utils/date';
import getAliasOrNameFromObject from '@/utils/objects';

export default {
  name: 'SourceInfoViewCard',
  props: {
    currentSourceInfo: {
      type: Object,
      required: false,
    },
    locations: {
      type: Array,
      required: true,
    },
  },
  components: {
    EditableField,
    ModalDialog,
    IconDropdownMenu,
    SelectorWithTags,
  },
  data() {
    const imageResizePossibleValues = [
      { iconSize: 'h-12 w-12', value: 0, label: this.$t('sourceInfoViewCard.originalSizeLabel') },
      { iconSize: 'h-9 w-9', value: 1, label: this.$t('sourceInfoViewCard.halfSizeLabel') },
      { iconSize: 'h-6 w-6', value: 2, label: this.$t('sourceInfoViewCard.quarterSizeLabel') },
      { iconSize: 'h-3 w-3', value: 3, label: this.$t('sourceInfoViewCard.eighthSizeLabel') },
    ];
    return {
      camIcon: getIcon('camIcon'),
      removeLocationIcon: getIcon('crossIcon'),
      sourceInfo: {
        id: -1,
        alias: '',
        name: '',
        url: '',
        location_ids: [],
        image_quality: 100,
        image_pyrdown: 0,
      },
      errorMessage: '',
      errorMessages: {
        name: '',
        url: '',
        location_ids: '',
      },
      submitButtonEnabled: false,
      areFieldsEdited: false,
      selectedLocations: [],
      dropdownOptions: [
        {
          id: 'delete-location',
          name: this.$t('sourceInfoViewCard.deleteSourceMenuText'),
          iconName: 'deleteIcon',
          buttonItemTextStyle: 'text-red-800',
        },
      ],
      imageResizePossibleValues,
      previousImageQuality: 100,
    };
  },
  setup() {
    const transitStore = useTransitStore();
    const userManagementStore = useUserManagementStore();
    return {
      transitStore,
      userManagementStore,
      getIcon,
      getLocalDateTimeFromTimestamp,
      getAliasOrNameFromObject,
    };
  },
  methods: {
    validateAlias(editedAlias) {
      this.sourceInfo.alias = editedAlias;
      this.joinValidations();
    },
    validateName(editedName) {
      this.errorMessages.name = (editedName?.length < 3) ? this.$t('sourceInfoViewCard.nameMinimumLenght') : '';
      this.sourceInfo.name = editedName;
      this.joinValidations();
    },
    validateDescription(editedDescription) {
      this.sourceInfo.description = editedDescription;
      this.joinValidations();
    },
    validateUrl(editedUrl) {
      this.errorMessages.url = '';
      if (!isValidUrl(editedUrl)) {
        this.errorMessages.url = this.$t('sourceInfoViewCard.urlNonValid');
      }
      this.sourceInfo.url = editedUrl;
      this.joinValidations();
    },
    validateLocations(editedLocations) {
      this.selectedLocations = [...editedLocations];
      this.errorMessages.location_ids = editedLocations?.length > 0 ? '' : this.$t('sourceInfoViewCard.mustSelectOneLocation');
      const selectedLocationsIds = [];
      this.selectedLocations.forEach((location) => selectedLocationsIds.push(location.id));
      this.sourceInfo.location_ids = selectedLocationsIds;
      this.joinValidations();
    },
    validateQuality() {
      this.sourceInfo.image_quality = parseInt(this.sourceInfo.image_quality, 10);
      this.joinValidations();
      if (this.sourceInfo.image_quality <= 50 && this.previousImageQuality > 50) {
        const modalInfo = {
          modalTitle: this.$t('sourceInfoViewCard.tooLowQualityModalTitle'),
          modalText: this.$t('sourceInfoViewCard.tooLowQualityModalText'),
          modalQuestion: this.$t('sourceInfoViewCard.tooLowQualityModalQuestion'),
          showCancelButton: true,
          signalOnCancel: 'reset-quality-value',
        };
        this.$refs.sourceInfoViewCardModal.openModal(modalInfo);
      }
    },
    presetQualityValue() {
      this.previousImageQuality = this.sourceInfo.image_quality;
    },
    resetQualityValue() {
      this.sourceInfo.image_quality = this.previousImageQuality;
      this.joinValidations();
    },
    setResizeValue(selectedImageResize) {
      this.sourceInfo.image_pyrdown = selectedImageResize.value;
      this.joinValidations();
    },
    joinValidations() {
      this.emitter.emit('close-other-combos');
      this.errorMessage = Object.values(this.errorMessages).filter(Boolean).join('\n');
      this.areFieldsEdited = (JSON.stringify(this.currentSourceInfo) !== JSON.stringify(this.sourceInfo));
      this.submitButtonEnabled = !this.errorMessage
      && this.areFieldsEdited
      && this.sourceInfo.name
      && this.sourceInfo.url
      && this.sourceInfo.location_ids?.length > 0;
    },
    showSourceChangedNameWarningIfNecessary() {
      if (!this.currentSourceInfo || this.currentSourceInfo.name === this.sourceInfo.name) {
        this.submitSource();
        return;
      }
      const modalInfo = {
        modalTitle: this.$t('sourceInfoViewCard.changedNameModalTitle'),
        modalText: this.$t('sourceInfoViewCard.changedNameModalText'),
        modalQuestion: this.$t('sourceInfoViewCard.changedNameModalQuestion'),
        showCancelButton: true,
        signalOnAccept: 'accept-change',
        signalOnCancel: 'cancel-change',
      };
      this.$refs.sourceInfoViewCardModal.openModal(modalInfo);
    },
    async submitSource() {
      let actionSuccessfull = false;
      const isEdition = this.currentSourceInfo?.name?.length > 0;
      if (isEdition) {
        await this.transitStore.editSource(this.sourceInfo).then((response) => { actionSuccessfull = response.ok; });
      } else {
        await this.transitStore.addSource(this.sourceInfo).then((response) => { actionSuccessfull = response.ok; });
      }
      this.emitter.emit('show-toast', {
        toastType: actionSuccessfull ? 'info' : 'error',
        toastText: this.$t(
          actionSuccessfull ? 'sourceInfoViewCard.sourceAddedSuccessfully' : 'sourceInfoViewCard.errorAddingSource',
          {
            sourceName: getAliasOrNameFromObject(this.sourceInfo),
            what: isEdition ? this.$t('sourceInfoViewCard.edited') : this.$t('sourceInfoViewCard.added'),
          },
        ),
      });

      this.$emit('source-updated');
      this.areFieldsEdited = false;
    },
    cancelSourceAddOrEdit() {
      this.$emit('source-updated');
      this.sourceInfo = { ...this.currentSourceInfo };
      this.errorMessage = '';
      this.areFieldsEdited = false;
    },
    resetSource() {
      this.sourceInfo = {
        id: -1,
        name: '',
        url: '',
        location_ids: [],
      };
    },
    showDeleteSourceWarning() {
      const modalInfo = {
        modalTitle: this.$t('sourceInfoViewCard.modalDeleteSourceTitle'),
        modalText: this.$t('sourceInfoViewCard.modalDeleteSourceText', { who: getAliasOrNameFromObject(this.sourceInfo) }),
        modalQuestion: this.$t('sourceInfoViewCard.modalDeleteSourceQuestion'),
        showCancelButton: true,
        signalOnAccept: 'delete-source',
      };
      this.$refs.sourceInfoViewCardModal.openModal(modalInfo);
    },
    async deleteSource() {
      this.transitStore.deleteSource(this.currentSourceInfo)
        .then((deleteSuccessfull) => {
          if (!deleteSuccessfull) {
            throw new Error();
          }
          this.emitter.emit('show-toast', {
            toastType: 'info',
            toastText: this.$t('sourceInfoViewCard.sourceDeletedSuccessfully', { who: getAliasOrNameFromObject(this.sourceInfo) }),
          });
          this.$emit('source-updated');
        })
        .catch(() => {
          this.emitter.emit('show-toast', {
            toastType: 'error',
            toastText: this.$t('sourceInfoViewCard.sourceDeleteFailed', { who: getAliasOrNameFromObject(this.sourceInfo) }),
          });
        });
    },
  },
  mounted() {
    this.sourceInfo = { ...this.currentSourceInfo };
    if (!this.sourceInfo.image_quality) {
      this.sourceInfo.image_quality = 100;
    }
    if (!this.sourceInfo.image_pyrdown) {
      this.sourceInfo.image_pyrdown = 0;
    }
    this.selectedLocations = this.transitStore.locations.filter((location) => this.sourceInfo.location_ids?.includes(location.id));
  },
};
</script>

<style scoped>
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 6px;
  background: #b3b3b3;
  border-radius: 5px;
  outline: none;
  transition: background 0.3s ease;
}

input[type="range"]:hover {
  background: #1e3c53;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  background: #1e3c53;
  border: 2px solid #d1d5db;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.2s ease, background 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

input[type="range"]::-webkit-slider-thumb:hover {
  background: #b3b3b3;
  transform: scale(1.1);
}

input[type="range"]::-webkit-slider-thumb:active {
  background: #d1d5db;
}

input[type="range"]::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background: #264257;
  border: 2px solid #d1d5db;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.2s ease, background 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

input[type="range"]::-moz-range-thumb:hover {
  background: #b3b3b3;
  transform: scale(1.1);
}

input[type="range"]::-moz-range-thumb:active {
  background: #264257;
}
</style>
