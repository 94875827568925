<template>
  <label :htmlFor="this.fieldId" class="w-fit">
    <textarea cols="30"
      rows="1"
      :id="this.fieldId"
      ref="textAreaElement"
      v-model="this.fieldValue"
      v-on:keyup="fieldEdited"
      v-on:blur="fieldEdited"
      v-on:input="switchEditableComponentIfNecessary"
      :placeholder="this.placeholder"
      autocomplete="off"
      spellcheck="false"
      :class="['flex bg-transparent font-semibold overflow-hidden resize-none align-text-bottom text-wrap',
        this.nonEditable ? 'cursor-not-allowed outline-none' : 'cursor-pointer hover:bg-gray-200 focus:font-normal focus:bg-white',
        'rounded-md p-1 place-self-start',
        this.fieldValue ? '' : 'min-w-[100px] bg-white border-2 border-gray-300 h-8'
      ]"></textarea>
  </label>
</template>

<script>
export default {
  name: 'EditableField',
  props: {
    fieldId: {
      type: String,
      required: true,
    },
    fieldValidator: {
      required: false,
      type: Function,
    },
    value: {
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    nonEditable: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['editing-field', 'finished-editing'],
  data() {
    return {
      fieldValue: this.value,
    };
  },
  methods: {
    preventReturn(event) {
      const keyCode = (event.keyCode ? event.keyCode : event.which);
      if (keyCode === 13) {
        event.preventDefault();
        event.target.blur();
      }
    },
    switchEditableComponentIfNecessary() {
      const textArea = this.$refs.textAreaElement;
      if (textArea) {
        textArea.style.display = 'block';
        textArea.style.height = 'auto';
        textArea.style.height = `${textArea.scrollHeight}px`;
      }
    },
    fieldEdited(event) {
      const keyCode = (event.keyCode ? event.keyCode : event.which);
      if (keyCode === 13 && !event.shiftKey) {
        this.removeCharFromFieldValueAt(this.$refs.textAreaElement.selectionStart)
          .then(() => {
            event.preventDefault();
            event.target.blur();
            this.switchEditableComponentIfNecessary();
          });
      } else if (this.fieldValidator) {
        this.fieldValidator(this.fieldValue);
      }
    },
    updateFieldValue() {
      this.fieldValue = this.value;
      this.$nextTick(() => {
        if (this.$refs.textAreaElement.scrollHeight > this.$refs.textAreaElement.clientHeight) {
          this.switchEditableComponentIfNecessary();
        }
      });
      this.switchEditableComponentIfNecessary();
    },
    async removeCharFromFieldValueAt(pos) {
      return new Promise((resolve) => {
        const tmpArray = this.fieldValue.split('');
        tmpArray.splice(pos - 1, 1);
        this.fieldValue = tmpArray.join('');
        this.$nextTick(() => resolve());
      });
    },
  },
  async mounted() {
    this.updateFieldValue();
  },
  watch: {
    value: 'updateFieldValue',
  },
};
</script>
