<template>
  <WheelEventCapturer>
    <div v-show="show"
      ref="popup"
      class="fixed inset-0 z-20 flex items-center justify-center bg-black bg-opacity-50"
      @click="$emit('close')"
      @keyup.esc="$emit('close')"
      tabindex="0">
      <div class="bg-gray-200 border border-gray-300 rounded-xl" @click.stop>
        <div class="relative bg-white rounded-xl m-3 p-3">
          <div class="flex gap-8 p-4">
            <div class="flex items-center gap-2">
              <div class="flex flex-col gap-4">
                <div class="flex items-center justify-between gap-2">
                  <label for="fromDate" class="flex-grow">
                    {{ $t('commonFilterPopUp.from') }}
                    <input id="fromDate" v-model="fromDate" type="datetime-local"
                      class="ml-2 p-2 rounded border border-gray-300 flex-grow" />
                  </label>
                </div>
                <div class="flex items-center justify-between gap-2">
                  <label for="toDate" class="flex-grow">
                    {{ $t('commonFilterPopUp.to') }}
                    <input id="toDate" v-model="toDate" type="datetime-local"
                      class="ml-5 p-2 rounded border border-gray-300 flex-grow" />
                  </label>
                </div>
              </div>
              <div class="flex flex-col gap-2">
                <button
                  v-for="(value, key) in dateLabelsEnum"
                  :key="key"
                  @click="setActiveButton(key)"
                  :class="[
                    'py-1 px-6 rounded-full border hover:text-sky-700 transition',
                    activeKey === key
                      ? 'bg-sky-500 text-white hover:bg-sky-700 hover:text-white'
                      : 'bg-white'
                  ]">
                  {{ value }}
                </button>
              </div>
            </div>
            <div class="inline-block min-h-[1em] w-0.5 self-stretch bg-sky-300">
            </div>
            <div class="flex flex-col items-end justify-start gap-3">
              <LocationFilter
                ref="locationFilter"
                @update:filterByLocation="handleFilterByLocationChange"
                uniqueComboName="objectsFilterLocationCombo"/>
              <MetaObjectTypeFilter
                ref="objectTypeFilter"
                uniqueComboName="metaObjectTypeFilter"
                @update:filterByMetaObjectType="handleFilterByObjectTypeChange"/>
              <div class="text-left flex flex-col gap-4 w-full">
                <p>{{ this.$t('objectFilterPopUp.confidenceRange') }}</p>
                <div class="mx-3" id="confidence_slider"></div>
              </div>
            </div>
          </div>
          <hr class="border-t-1 border-dashed border-gray-300 mt-6 mb-2"/>
          <div class="flex gap-6 justify-between">
            <button @click="clearFilter"
              class="mt-2 px-4 py-2 text-gray-600 hover:font-bold underline underline-offset-4 hover:decoration-4 hover:text-rose-950">
              {{ $t('commonFilterPopUp.clear') }}
            </button>
            <button @click="applyFilter"
              class="mt-2 px-3 py-1 bg-sky-500 text-white rounded
                hover:bg-sky-700 transition-colors duration-300">
                {{ $t('commonFilterPopUp.applyFilter') }}
            </button>
          </div>
          <button @click="$emit('close')"
          class="absolute -top-2 -right-2"
          :title="$t('commonFilterPopUp.closePopup')">
            <svg width="36px" height="36px" viewBox="0 0 24 24" fill="none"
            xmlns="http://www.w3.org/2000/svg" class="bg-white rounded-full hover:bg-gray-200">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <path d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2
                12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"></path>
                <path d="M8.96967 8.96967C9.26256 8.67678 9.73744 8.67678 10.0303 8.96967L12
                10.9394L13.9697 8.96969C14.2626 8.6768 14.7374 8.6768 15.0303 8.96969C15.3232
                9.26258 15.3232 9.73746 15.0303 10.0304L13.0607 12L15.0303 13.9696C15.3232
                14.2625 15.3232 14.7374 15.0303 15.0303C14.7374 15.3232 14.2625 15.3232 13.9696
                15.0303L12 13.0607L10.0304 15.0303C9.73746 15.3232 9.26258 15.3232 8.96969
                15.0303C8.6768 14.7374 8.6768 14.2626 8.96969 13.9697L10.9394 12L8.96967
                10.0303C8.67678 9.73744 8.67678 9.26256 8.96967 8.96967Z" fill="#000000"></path>
              </g>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </WheelEventCapturer>
</template>

<script>
import wNumb from 'wnumb';
import moment from 'moment';
import noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';

import { getLocalDateTimeFromTimestampInFilter } from '@/utils/date';
import WheelEventCapturer from '@/components/generic/WheelEventCapturer.vue';
import MetaObjectTypeFilter from './MetaObjectTypeFilter.vue';
import LocationFilter from './LocationFilter.vue';

export default {
  name: 'ObjectFilterPopUp',
  components: {
    MetaObjectTypeFilter,
    LocationFilter,
    WheelEventCapturer,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
      required: true,
    },
    metaObjectTypes: {
      type: Object,
      required: true,
    },
    locations: {
      type: Object,
      required: true,
    },
    currentFetchParams: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fromDate: null,
      toDate: null,
      confidenceSlider: null,
      dateLabelsEnum: {
        today: this.$t('commonFilterPopUp.today'),
        week: this.$t('commonFilterPopUp.lastWeek'),
        month: this.$t('commonFilterPopUp.lastMonth'),
        year: this.$t('commonFilterPopUp.lastYear'),
      },
      activeKey: null,
      confidenceRange: [0, 100],
      objectsTypeSelected: [],
      locationsSelected: [],
    };
  },
  watch: {
    locations: 'buildLocationsCombo',
    metaObjectTypes: 'buildObjectTypesCombo',
    show: 'takeFocusIfShown',
  },
  methods: {
    setActiveButton(key) {
      this.activeKey = this.activeKey === key ? null : key;
      if (this.activeKey === null) return;
      this.toDate = moment().format('YYYY-MM-DDTHH:mm');
      if (key === 'today') {
        this.fromDate = moment().format('YYYY-MM-DDT00:00');
      } else {
        this.fromDate = moment().subtract(1, key).format('YYYY-MM-DDTHH:mm');
      }
    },
    applyFilter() {
      const filters = {
        ...this.currentFetchParams,
        fromDate: moment(this.fromDate).valueOf() / 1000,
        toDate: moment(this.toDate).valueOf() / 1000,
        confidenceRange: [this.confidenceRange[0] / 100.0, this.confidenceRange[1] / 100.0],
        typeList: this.objectsTypeSelected,
        locations: this.locationsSelected,
        activeKey: this.activeKey,
      };
      this.$emit('filter-updated', filters);
      this.$emit('close');
    },
    clearFilter() {
      this.fromDate = null;
      this.toDate = null;
      this.confidenceRange = [0, 100];
      this.confidenceSlider.noUiSlider.set(this.confidenceRange);
      this.objectsTypeSelected = [];
      this.locationsSelected = [];
      this.activeKey = null;

      this.$refs.locationFilter.selectAllOptions();
      this.$refs.objectTypeFilter.selectAllOptions();
    },
    buildObjectTypesCombo() {
      this.$refs.objectTypeFilter.computeObjectTypesArray(this.metaObjectTypes);
    },
    buildLocationsCombo() {
      this.$refs.locationFilter.computeLocationsArray(this.locations);
    },
    handleFilterByObjectTypeChange(objectsSelected) {
      this.objectsTypeSelected = objectsSelected;
    },
    handleFilterByLocationChange(locationsSelected) {
      this.locationsSelected = locationsSelected;
    },
    takeFocusIfShown() {
      if (this.show) {
        this.$nextTick(() => this.$refs.popup.focus());
      }
    },
  },
  mounted() {
    this.confidenceSlider = document.getElementById('confidence_slider');
    noUiSlider.create(this.confidenceSlider, {
      start: [this.confidenceRange[0], this.confidenceRange[1]],
      tooltips: wNumb({ decimals: 0, suffix: '%' }),
      connect: true,
      range: {
        min: 0,
        max: 100,
      },
      step: 1,
    });
    this.confidenceSlider.noUiSlider.on('change', (newRanges) => {
      this.confidenceRange = [...newRanges];
    });
  },
  created() {
    if (!sessionStorage.objectsFilter) {
      sessionStorage.objectsFilter = '';
    } else {
      const filters = Object(JSON.parse(sessionStorage.objectsFilter));
      this.fromDate = filters.fromDate ? getLocalDateTimeFromTimestampInFilter(filters.fromDate * 1000) : null;
      this.toDate = filters.toDate ? getLocalDateTimeFromTimestampInFilter(filters.toDate * 1000) : null;
      this.confidenceRange = [filters.confidenceRange[0] * 100, filters.confidenceRange[1] * 100];
      this.objectsTypeSelected = filters.typeList;
      this.locationsSelected = filters.locations;
      this.activeKey = filters.activeKey;
    }
    this.applyFilter();
  },
};
</script>
