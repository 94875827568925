<template>
  <div @wheel="handleWheel" class="inline-block">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'WheelEventCapturer',
  props: {
    propagateEvent: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    handleWheel(event) {
      event.preventDefault();
      event.stopPropagation();
      if (this.propagateEvent) {
        this.$emit('wheel-event', event);
      }
    },
  },
};
</script>
