<template>
  <WheelEventCapturer>
    <div v-if="show"
      ref="popupVideoViewer"
      class="fixed inset-0 z-20 flex items-center justify-center bg-black bg-opacity-50"
      @click="closePopup()"
      @keyup.esc="closePopup()"
      tabindex="0">
      <ToastComponent ref="toastOnPopUp" class="mx-auto"/>
      <div
        class="bg-gray-200 relative p-5 w-4/5 overflow-auto flex flex-col h-[85%] rounded-xl"
        @click.stop>
        <button @click="closePopup()"
          class="absolute top-3 right-3 rounded-full bg-white hover:bg-gray-200 p-3"
          :title="this.$t('popUpImage.closePopup')">
          <img :src="getIcon('crossIcon')" class="w-3 h-3" alt="close button"/>
        </button>
        <div
          :class="['flex flex-row bg-white rounded-t-xl',
            this.transitSections.length > 1 ? 'h-[80%]' : 'h-full'
          ]">
          <div
            v-if="this.isFetchingVideo"
            class="inline-flex flex-col w-full justify-center items-center bg-[#1e3c53] rounded-tl-xl rounded-br-xl">
            <div
              class="flex align-middle items-center justify-center h-fit w-fit mx-3
                p-4 border rounded-xl bg-sky-100 border-sky-500 text-sky-800">
              <p class="flex text-2xl font-bold gap-6 items-center">
                {{ this.$t('popUpVideo.loadingVideo')}}
                <img :src="getIcon('loadingGif')" class="w-16 h-16" alt="Loading data gif"/>
              </p>
            </div>
          </div>
          <div v-else
            class="bg-[#1e3c53] p-2 basis-4/5 flex items-center justify-center max-h-full rounded-tl-xl rounded-br-xl">
            <!-- video player -->
            <!-- eslint-disable-next-line -->
            <video
              ref="videoPlayer"
              v-if="initialize() && thereAreVideos() && !this.fetchError"
              controls
              class="w-full h-full"
              :src="this.selectedVideo"
              @timeupdate="setSectionActiveOnCurrentTimeChanged"
              @loadedmetadata="setCurrentTime">
              {{ this.$t('popUpVideo.browserDoesNotSupportVideoTag') }}
            </video>
            <div v-else class="p-4 mt-4 border rounded bg-red-50 border-red-200 text-red-800">
              <p class="text-2xl font-bold">
                {{ this.fetchError }}
              </p>
            </div>
          </div>
          <div class="basis-1/5 h-full p-2">
            <!-- video information -->
            <div class="flex justify-center items-start gap-2">
              <img :src="this.locationIcon" class="w-14 h-14"
                alt="transit type icon">
            </div>
            <hr class="border-t-2 border-gray-300 my-2"/>
            <div class="grid grid-cols-3 cursor-default text-sm text-left w-auto gap-1 text-ellipsis overflow-hidden">
              <p>{{ this.$t('popUpImage.from') }}</p>
              <p class="col-span-2"><b>{{ this.selectedVideoData.streamName }}</b></p>
              <p>{{ this.$t('popUpImage.at') }}</p>
              <p class="col-span-2"><b>{{ getLocalDateTimeFromTimestamp(this.transit.start_time) }}</b></p>
              <p>{{ this.$t('transitCard.duration') }}</p>
              <p class="col-span-2"><b>{{ getDuration(this.transit.start_time, this.transit.end_time) }}</b></p>
            </div>
            <hr class="border-t-2 border-gray-300 my-2"/>
            <div class="text-left bg-gray-200 overflow-auto rounded-xl">
              <div class="text-sm bg-gray-300 font-semibold border-b-2 border-gray-400 p-2 pt-3">
                {{ this.$t('popUpVideo.availableVideos') }}
              </div>
              <div v-for="(video, video_index) in this.videos" :key="video_index" class="relative">
                <button
                  :class="['h-9 p-1 w-full text-left text-xs',
                          (video_index % 2 === 0) ? 'bg-gray-200' : 'bg-gray-100',
                          (this.selectedVideoData === video) ? 'bg-sky-200 text-sky-900' : 'hover:bg-sky-100']"
                  @click="changeSelectedVideo(video)">
                  <p>{{ this.$t('popUpVideo.videoFrom') }}<b>{{ video.streamName }}</b></p>
                </button>
                <button v-on:click="downloadVideo(video)"
                  :disabled="video.disabled"
                  class="absolute top-1 right-2 bg-white rounded-xl w-fit h-fit px-2 py-1 transition-colors
                    duration-200 ease-in-out transform hover:bg-gray-300 hover:shadow-lg hover:scale-105 disabled:scale-100 disabled:bg-gray-50"
                    :title="this.$t('popUpVideo.downloadVideo')">
                  <i> <img
                      :src="video.disabled ? getIcon('mediaDownloadDisabledIcon') : getIcon('mediaDownloadIcon')"
                      alt="download video icon"
                      class="w-5 h-5"/>
                  </i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="this.transitSections.length > 1"
          :class="['min-h-[120px] flex gap-2 w-full bg-white rounded-b-xl overflow-auto p-2 h-[20%]']">
          <!-- sections -->
          <div
            v-for="(section, index) in this.transitSections"
            v-bind:key="index"
            :class="this.isTrain ? 'bg-gray-200 p-2 rounded-md place-content-end' : ''">
            <div class="flex gap-2 place-content-end h-full">
              <div
              class="section-element flex place-items-end h-full"
              :sectionId=section.section_id>
                <CardSection
                  v-on:click="goToSectionFrame(section)"
                  :class="['px-1 pb-1 pt-0 rounded-md m-0 border border-gray-200 cursor-pointer h-full',
                    section === this.activeSection ? 'bg-sky-200 font-bold text-sky-900' : 'bg-white hover:bg-sky-100']"
                  :section="section"
                  :borderless=true
                  :reduced=true
                  :editable=false
                  :sectionIndex=index />
              </div>
              <div
                v-for="(child_meta_object, child_meta_object_index) in section.childMetaObjects"
                v-bind:key="child_meta_object_index"
                class="section-element h-full"
                :sectionId=child_meta_object.section_id>
                <CardSection
                  v-on:click="goToSectionFrame(child_meta_object)"
                  :class="['px-1 pb-1 pt-0 rounded-md m-0 border border-gray-200 cursor-pointer h-full',
                  child_meta_object === this.activeSection ? 'bg-sky-200 font-bold text-sky-900' : 'bg-white hover:bg-sky-100']"
                  :section="child_meta_object"
                  :borderless=true
                  :reduced=true
                  :editable=false
                  :sectionIndex=child_meta_object_index />
              </div>
            </div>
            <div
              class="flex"
              v-if="this.isTrain">
              <img :src='wagonLeftSide' class="w-4 h-4" alt="Wagon left side"/>
              <img :src='wagonCenter' class="w-full h-4" alt="Wagon center"/>
              <img :src='wagonRightSide' class="w-4 h-4" alt="Wagon right side"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </WheelEventCapturer>
</template>

<script>
import useTransitStore from '@/stores/TransitStore';
import closeButton from '@/assets/img/close-button.png';
import wagonLeftSide from '@/assets/img/wagon_left_side.png';
import wagonRightSide from '@/assets/img/wagon_right_side.png';
import wagonCenter from '@/assets/img/wagon_center.png';
import {
  getLocalDateTimeFromTimestamp,
  getTimestampFromLocalDate,
  getDuration,
} from '@/utils/date';
import { getIconName, getIcon } from '@/utils/icons';
import getAliasOrNameFromObject from '@/utils/objects';
import { httpStatus } from '@/utils/http_status';
import WheelEventCapturer from '@/components/generic/WheelEventCapturer.vue';
import ToastComponent from '@/components/generic/ToastComponent.vue';
import CardSection from '@/components/CardSection.vue';

const RELOAD_VIDEO_MAX_ATTEMPTS = 4;

export default {
  props: ['show', 'transit', 'transitSections', 'isTrain'],
  name: 'PopupVideo',
  components: {
    ToastComponent,
    CardSection,
    WheelEventCapturer,
  },
  setup() {
    return {
      transitStore: useTransitStore(),
      getLocalDateTimeFromTimestamp,
      getDuration,
      getIcon,
      wagonLeftSide,
      wagonRightSide,
      wagonCenter,
    };
  },
  data() {
    return {
      closeButton,
      initialized: false,
      isFetchingVideo: false,
      locationIcon: '',
      fetchError: '',
      activeSection: {},
      selectedVideo: '',
      selectedVideoData: {},
      selectedVideoBlob: '',
      tryReloadVideoTimerId: null,
      tryReloadVideoCounter: 0,
      videos: [],
      videoTimes: [],
      currentTime: 0,
    };
  },
  methods: {
    initialize() {
      if (!this.initialized) {
        if (this.videos.length > 0) {
          this.changeSelectedVideo(this.videos[0]);
        } else {
          this.fetchError = this.$t('popUpVideo.noAvailableVideos');
        }
        this.initialized = true;
      }
      return true;
    },
    uninitialize() {
      if (this.selectedVideo) {
        URL.revokeObjectURL(this.selectedVideoBlob);
        this.selectedVideo = '';
        this.selectedVideoData = {};
      }
      if (this.tryReloadVideoTimerId) {
        clearTimeout(this.tryReloadVideoTimerId);
        this.tryReloadVideoTimerId = null;
      }
      this.currentTime = 0;
      this.fetchError = '';
      this.activeSection = {};
      this.initialized = false;
      this.isFetchingVideo = false;
    },
    thereAreVideos() {
      return this.videos.length !== 0;
    },
    closePopup() {
      this.uninitialize();
      this.$emit('close');
    },
    updateTransitIcon() {
      const location = this.transitStore.locationMap.get(this.transit.location_id);
      this.locationIcon = getIcon(getIconName(location?.transit_type));
    },
    updateTransitVideos() {
      this.videos.length = 0;
      this.transit.videos.forEach((video) => {
        const stream = this.transitStore.streamMap.get(video.stream_id);
        const streamAliasOrName = getAliasOrNameFromObject(stream);
        this.videos.push({
          streamName: streamAliasOrName,
          streamId: video.stream_id,
          transitId: this.transit.id,
          disabled: false,
        });
      });
    },
    takeFocusIfShown() {
      if (this.show) {
        this.updateTransitIcon();
        this.updateTransitVideos();
        this.updateSectionTimes();
        this.$nextTick(() => this.$refs.popupVideoViewer.focus());
      }
    },
    async downloadVideo(downloadVideoObject) {
      try {
        const data = await this.transitStore.fetchVideo(downloadVideoObject.streamId, downloadVideoObject.transitId);
        const videoBlob = URL.createObjectURL(data.blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        a.href = videoBlob;
        a.download = data.filename;
        a.click();
        a.remove();
        URL.revokeObjectURL(videoBlob);
      } catch (error) {
        this.emitter.emit('show-toast', { toastType: 'error', toastText: error });
      }
    },
    async changeSelectedVideo(newSelectedVideoData) {
      if (this.selectedVideoData !== newSelectedVideoData) {
        this.selectedVideoData = newSelectedVideoData;
        if (this.selectedVideo) {
          this.currentTime = this.$refs.videoPlayer.currentTime;
          URL.revokeObjectURL(this.selectedVideoBlob);
          this.selectedVideo = '';
        }
      }
      const videoIndex = this.videos.indexOf(newSelectedVideoData);
      if (videoIndex === -1) {
        this.fetchError = this.$t('transitStore.errorFetchVideo');
        this.isFetchingVideo = false;
        this.tryReloadVideoTimerId = null;
        console.error('selected video does not exist');
        return;
      }
      try {
        this.selectedVideoBlob = await this.transitStore.fetchVideoInline(
          this.selectedVideoData.streamId,
          this.selectedVideoData.transitId,
        );
        this.fetchError = '';
        this.selectedVideo = URL.createObjectURL(this.selectedVideoBlob);
        this.isFetchingVideo = false;
        this.tryReloadVideoTimerId = null;
        this.videos[videoIndex].disabled = false;
      } catch (error) {
        if (error.cause === httpStatus.NOT_FOUND || this.tryReloadVideoCounter >= RELOAD_VIDEO_MAX_ATTEMPTS) {
          this.emitter.emit('show-toast', { toastType: 'error', toastText: error });
          this.fetchError = error.message;
          this.videos[videoIndex].disabled = true;
          this.tryReloadVideoCounter = 0;
          this.tryReloadVideoTimerId = null;
          this.isFetchingVideo = false;
        } else {
          this.isFetchingVideo = true;
          this.tryReloadVideoTimerId = setTimeout(() => this.changeSelectedVideo(newSelectedVideoData), 2000);
          this.tryReloadVideoCounter += 1;
          this.videos[videoIndex].disabled = true;
        }
      }
    },
    goToSectionFrame(section) {
      const video = this.$refs.videoPlayer;
      if (video !== null) {
        video.pause();
        const detectionTime = section.time / 1000;
        video.currentTime = detectionTime - getTimestampFromLocalDate(this.transit.start_time);
      }
    },
    updateSectionTimes() {
      this.videoTimes.length = 0;
      if (!this.transitSections.length) {
        return;
      }
      const transitStartTime = getTimestampFromLocalDate(this.transit.start_time);
      const transitEndTime = getTimestampFromLocalDate(this.transit.end_time);
      const sortedSections = [...this.transitSections];
      sortedSections.sort((section1, section2) => section1.time >= section2.time);
      sortedSections.forEach((transitSection) => {
        let sectionTime = transitSection.time / 1000 - transitStartTime;
        this.videoTimes.push({ time: sectionTime, section: transitSection, sectionId: transitSection.section_id });
        transitSection.childMetaObjects.forEach((childTransitSection) => {
          sectionTime = childTransitSection.time / 1000 - transitStartTime;
          this.videoTimes.push({ time: sectionTime, section: childTransitSection, sectionId: childTransitSection.section_id });
        });
      });
      const checkVideoDuration = () => {
        if (this.$refs.videoPlayer && this.$refs.videoPlayer.duration) {
          for (let i = 0; i < this.videoTimes.length; i += 1) {
            if (i < this.videoTimes.length - 1) {
              this.videoTimes[i].nextTime = this.videoTimes[i + 1].time;
            } else {
              this.videoTimes[i].nextTime = transitEndTime - transitStartTime;
            }
          }
        } else {
          setTimeout(checkVideoDuration, 100);
        }
      };
      checkVideoDuration();
    },
    setCurrentTime() {
      if (this.$refs.videoPlayer) {
        this.$refs.videoPlayer.currentTime = this.currentTime;
      }
    },
    setSectionActiveOnCurrentTimeChanged() {
      const currentTime = this.$refs.videoPlayer?.currentTime;
      const videoIndex = this.videoTimes.findIndex((videoTime) => (videoTime.time <= currentTime && currentTime < videoTime.nextTime));
      if (videoIndex < 0) {
        this.activeSection = {};
        return;
      }
      this.activeSection = this.videoTimes[videoIndex].section;
      const lastSectionElement = this.$el.querySelector(`.section-element[sectionId="${this.activeSection.section_id}"]`);
      if (lastSectionElement) {
        lastSectionElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      }
    },
  },
  watch: {
    show: 'takeFocusIfShown',
  },
  unmounted() {
    this.uninitialize();
  },
};
</script>
