<template>
  <div
    ref="imageContainer"
    class="w-full h-full flex justify-center"
    @mouseleave="handleMouseLeave"
    @focusin="preventReturn"
    @focusout="preventReturn"
    @wheel="handleWheel">
    <img
      id="sourceImage"
      ref="src"
      class="object-contain aspect-auto cursor-zoom-in"
      :src="this.src"
      alt="Source image to magnify"
      v-on:mousemove="moveMagnifier"
      v-on:touchmove="moveMagnifier"/>
      <div
        v-show="this.computedRoi !== null"
        :style="{
          ...this.computedRoi,
          transition: 'all 400ms ease',
        }"
        class="fixed border-2 border-sky-400 pointer-events-none"></div>
      <div
        :class="['absolute pt-2 transition-opacity duration-700 pointer-events-none', this.isGlassHintShown ? 'opacity-100' : 'opacity-0']">
        <div class="bg-gray-800 bg-opacity-70 text-white text-center rounded px-2 py-1 text-sm w-fit h-fit">
          <p>{{ this.$t('popUpImage.hoverToZoom') }}</p>
          <p>{{ this.$t('popUpImage.wheelToChangeZoomLevel') }}</p>
        </div>
      </div>
      <span :class="['absolute pt-2 transition-opacity duration-300 pointer-events-none',
        'bg-white bg-opacity-60 text-center rounded px-2 py-1 text-sm w-fit h-fit',
        this.zoomLevelChanged ? 'opacity-100' : 'opacity-0']">
        {{ this.$t('popUpImage.zoomLevelLabel', { zoomLevel: this.zoomLevel.toFixed(2) }) }}
      </span>
    </div>
  <div
    v-show="this.hovered"
    id="glass"
    ref="glass"
    class="absolute border-2 border-sky-700 rounded-full border-solid pointer-events-none"></div>
</template>

<script>

const MAX_ZOOM_LEVEL = 3.0;
const MIN_ZOOM_LEVEL = 1.0;
const ZOOM_STEP = 0.1;

export default {
  props: ['src', 'detectionRoi'],
  name: 'ImageMagnifier',
  setup() {
    return {
      zoomLevel: 1,
    };
  },
  data() {
    return {
      hovered: false,
      isGlassHintShown: 1,
      dims: {},
      zoomLevelChanged: 0,
      computedRoi: null,
    };
  },
  methods: {
    updateDimensions() {
      const srcImageRect = this.$refs.src.getBoundingClientRect();
      const glassRect = this.$refs.glass.getBoundingClientRect();
      const { naturalWidth, naturalHeight } = this.$refs.src;

      this.dims.imageTop = srcImageRect.top;
      this.dims.imageLeft = srcImageRect.left;
      this.dims.imageWidth = srcImageRect.width;
      this.dims.imageHeight = srcImageRect.height;
      this.dims.naturalWidth = naturalWidth;
      this.dims.naturalHeight = naturalHeight;
      this.dims.scale = Math.min(srcImageRect.width / naturalWidth, srcImageRect.height / naturalHeight);
      this.dims.displayedImageWidth = this.dims.naturalWidth * this.dims.scale;
      this.dims.displayedImageHeight = this.dims.naturalHeight * this.dims.scale;
      this.dims.marginX = (this.dims.imageWidth - this.dims.displayedImageWidth) / 2;
      this.dims.marginY = (this.dims.imageHeight - this.dims.displayedImageHeight) / 2;
      this.dims.glassDivLeft = glassRect.left;
      this.dims.glassDivTop = glassRect.top;
      this.dims.glassDivWidth = glassRect.width;
      this.dims.glassDivHeight = glassRect.height;
    },
    computeGlassLensBackgroundSize() {
      this.$refs.glass.style.backgroundSize = `${this.dims.naturalWidth
      * this.zoomLevel}px ${this.dims.naturalHeight * this.zoomLevel}px`;
    },
    computeGlassLensSize() {
      const halfDims = (this.dims.imageWidth + this.dims.imageHeight) / 2.0;
      this.$refs.glass.style.width = `${halfDims / 5}px`;
      this.$refs.glass.style.height = this.$refs.glass.style.width;
    },
    computeRoi() {
      if (!this.detectionRoi || !this.$refs.src || !this.detectionRoi.width || !this.detectionRoi.height) {
        this.computedRoi = null;
        return;
      }

      const topPx = (this.detectionRoi.y / this.dims.naturalHeight) * this.dims.displayedImageHeight + this.dims.imageTop + this.dims.marginY;
      const leftPx = (this.detectionRoi.x / this.dims.naturalWidth) * this.dims.displayedImageWidth + this.dims.imageLeft + this.dims.marginX;
      const widthPx = (this.detectionRoi.width / this.dims.naturalWidth) * this.dims.displayedImageWidth;
      const heightPx = (this.detectionRoi.height / this.dims.naturalHeight) * this.dims.displayedImageHeight;

      this.computedRoi = {
        top: `${topPx}px`,
        left: `${leftPx}px`,
        width: `${widthPx}px`,
        height: `${heightPx}px`,
      };
    },
    setGlassPosition(moveEvent) {
      let cursorX = moveEvent.clientX - (this.dims.imageLeft + this.dims.marginX);
      cursorX = Math.max(0, Math.min(cursorX, this.dims.displayedImageWidth));

      let cursorY = moveEvent.clientY - (this.dims.imageTop + this.dims.marginY);
      cursorY = Math.max(0, Math.min(cursorY, this.dims.displayedImageHeight));

      const glassX = (cursorX / this.dims.displayedImageWidth) * this.dims.naturalWidth * this.zoomLevel;
      const glassY = (cursorY / this.dims.displayedImageHeight) * this.dims.naturalHeight * this.zoomLevel;
      const w = (this.dims.glassDivWidth / 2);
      const h = (this.dims.glassDivHeight / 2);
      this.$refs.glass.style.left = `${Math.max(0, moveEvent.layerX - this.dims.glassDivWidth)}px`;
      this.$refs.glass.style.top = `${Math.max(0, moveEvent.layerY)}px`;
      this.$refs.glass.style.backgroundPosition = `-${Math.max(0, glassX - w)}px -${Math.max(0, glassY - h)}px`;
    },
    isMoveInsideImage(moveEvent) {
      return moveEvent.layerX >= this.$refs.src.offsetLeft + this.dims.marginX
      && moveEvent.layerY >= this.$refs.src.offsetTop + this.dims.marginY
      && moveEvent.layerX < this.dims.imageWidth + this.$refs.src.offsetLeft - this.dims.marginX
      && moveEvent.layerY < this.dims.imageHeight + this.$refs.src.offsetTop - this.dims.marginY;
    },
    moveMagnifier(moveEvent) {
      moveEvent.preventDefault();
      moveEvent.stopPropagation();
      this.updateDimensions();
      this.computeGlassLensBackgroundSize();
      this.computeGlassLensSize();
      this.setGlassPosition(moveEvent);
      this.hovered = this.isMoveInsideImage(moveEvent);
      if (!this.hovered) {
        this.handleMouseLeave();
        return true;
      }
      this.isGlassHintShown = 0;
      return false;
    },
    loadImage() {
      this.$refs.src.onload = () => {
        this.$refs.glass.style.backgroundImage = `url(${this.src})`;
        this.$refs.glass.style.backgroundRepeat = 'no-repeat';
        this.updateDimensions();
        this.computeGlassLensBackgroundSize();
        this.computeGlassLensSize();
        this.hovered = false;
        this.zoomLevel = 1;

        this.computeRoi();
      };

      if (this.$refs.src?.complete) {
        this.$refs.src.onload();
      }
    },
    handleWheel(event) {
      event.preventDefault();
      event.stopPropagation();
      if (event.deltaY > 0) {
        this.zoomLevel = Math.min(this.zoomLevel + ZOOM_STEP, MAX_ZOOM_LEVEL);
      } else if (event.deltaY < 0) {
        this.zoomLevel = Math.max(this.zoomLevel - ZOOM_STEP, MIN_ZOOM_LEVEL);
      }
      this.moveMagnifier(event);
      if (this.zoomLevelChanged) {
        clearTimeout(this.zoomLevelChanged);
      }
      this.zoomLevelChanged = setTimeout(() => { this.zoomLevelChanged = 0; }, 1000);
    },
    handleMouseLeave() {
      this.hovered = false;
      this.isGlassHintShown = setTimeout(() => { this.isGlassHintShown = 0; }, 3000);
    },
  },
  mounted() {
    this.$el.addEventListener('wheel', this.handleWheel, { passive: false });
    window.addEventListener('resize', this.loadImage);

    this.loadImage();
  },
  unmounted() {
    this.$el.removeEventListener('wheel', this.handleWheel, { passive: false });
    window.removeEventListener('resize', this.loadImage);
  },
  watch: {
    src: 'loadImage',
  },
};
</script>
