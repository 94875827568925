import { apiUrl } from '@/../TransitViewerConfig';
import { httpStatus, httpStatusType } from '@/utils/http_status';

function hasAttachment(response) {
  const contentDisposition = response.headers.get('Content-Disposition');
  return contentDisposition ? contentDisposition.toLowerCase().includes('attachment') : false;
}

const baseReturnRequest = {
  ok: false,
  blob: null,
  headers: null,
  content: '',
  no_content: true,
  status: httpStatus.NO_CONTENT,
};

function wait(delay) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

function httpMethodRetry(url, delay, attempts, fetchOptions = {}) {
  let triesLeft = attempts;
  function onError(err) {
    triesLeft = attempts - 1;
    if (!triesLeft) {
      throw err;
    }
    return wait(delay).then(() => httpMethodRetry(url, delay, triesLeft, fetchOptions));
  }
  return fetch(url, fetchOptions).catch(onError);
}

async function methodAPI(httpMethod, endpoint, token, options) {
  const defaultOptions = {
    method: httpMethod,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  const requestOptions = { ...defaultOptions, ...options };
  requestOptions.headers = { ...defaultOptions.headers, ...options.headers };

  return httpMethodRetry(endpoint, 1000, 3, requestOptions).then(async (response) => {
    const returnResponse = {
      ...baseReturnRequest,
      status: response.status,
      ok: response.ok,
      headers: response.headers,
    };

    if (response.status === httpStatus.UNAUTHORIZED) {
      window.dispatchEvent(new Event('user-token-expired'));
      const apiRequestError = new Error(`API returned error code [${response.status}]:${httpStatusType[response.status]}`);
      apiRequestError.cause = response.status;
      console.error(apiRequestError.message);
      throw apiRequestError;
    }

    if (!response.ok
      || (response.ok && response.status === httpStatus.NO_CONTENT)) {
      return returnResponse;
    }

    const contentType = response.headers.get('content-type');
    switch (contentType.length > 0) {
      case hasAttachment(response):
      case contentType?.indexOf('image/jpeg') !== -1:
      case contentType?.indexOf('video/mp4') !== -1:
        returnResponse.blob = await response.blob();
        returnResponse.no_content = false;
        break;
      case contentType?.indexOf('text/plain') !== -1:
        returnResponse.content = await response.text();
        break;
      case contentType?.indexOf('application/json') !== -1:
        returnResponse.content = await response.json();
        break;
      default:
        break;
    }

    returnResponse.no_content = returnResponse.content === '';
    return returnResponse;
  });
}

export async function getUrlData(endpoint, token, options = {}) {
  return methodAPI('GET', endpoint, token, options);
}

export async function getAPIData(endpoint, token, options = {}) {
  return getUrlData(`${apiUrl()}${endpoint}`, token, options);
}

export async function postAPIData(endpoint, token, options = {}) {
  return methodAPI('POST', `${apiUrl()}${endpoint}`, token, options);
}

export async function putAPIData(endpoint, token, options = {}) {
  return methodAPI('PUT', `${apiUrl()}${endpoint}`, token, options);
}

export async function deleteAPIData(endpoint, token, options = {}) {
  return methodAPI('DELETE', `${apiUrl()}${endpoint}`, token, options);
}
